enum RouteName {
    LOGIN = 'Login',
    HOME = 'Home',
    USER_OVERVIEW = 'UserOverview',
    CREATE_SHIPMENT = 'CreateShipment',
    SHIPMENT_OVERVIEW = 'ShipmentOverview',
    PARCEL_TRACKING = 'ParcelTracking',
    TRANSPORTATION_OVERVIEW = 'TransportationOverview',
    TRANSPORTATION_LIST_CREATE = 'TransportationListCreate',
    REPRINT_LABEL = 'ReprintLabel',
    PARCEL_DATA = 'ParcelData'
}

export default RouteName;
