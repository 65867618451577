enum Role {
    PARTNER_CREATOR = 'PartnerCreator',
    PARTNER_OPERATOR = 'PartnerOperator',
    GLS_ADMIN = 'GLSAdmin',
    CUSTOMER_FRONTEND = 'CustomerFrontend',
    PARTNER_CUSTOMER_SERVICE = 'PartnerCustomerService',
    CREATE_SHIPMENT = 'CreateShipment',
    SHIPMENT_OVERVIEW = 'ShipmentOverview',
    CREATE_TRANSPORTATION_LIST = 'CreateTransportationList',
    TRANSPORTATION_OVERVIEW = 'TransportationOverview',
    PARCEL_TRACKING = 'ParcelTracking',
    PARCEL_DATA = 'ParcelData',
    REPRINT_LABEL = 'ReprintLabel'
}

export default Role;
