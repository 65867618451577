const configureDynatrace = (appId: string): void => {
    const scriptElement: HTMLScriptElement = document.createElement('script');
    scriptElement.src = `https://js-cdn.dynatrace.com/jstag/164ae1b51de/bf90494dmi/${appId}_complete.js`;
    scriptElement.type = 'text/javascript';
    scriptElement.setAttribute('crossOrigin', 'anonymous');

    document.head.appendChild(scriptElement);
};

export { configureDynatrace };
