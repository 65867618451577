import { storeToRefs } from 'pinia';
import mainStore from '@/stores/main-store';

const useMainStore = () => {
    const { accessToken, oid, attributeStoreData, userId, username, userType, isPartner, isCustomer, isAdmin } = storeToRefs(mainStore());

    return {
        accessToken,
        oid,
        attributeStoreData,
        username,
        userId,
        userType,
        isPartner,
        isCustomer,
        isAdmin
    };
};

export default useMainStore;
