import { jwtDecode } from 'jwt-decode';

const getOidFromToken = (token: string | null): string | null => {
    if (!token) {
        return null;
    }

    const tokenClaims: Record<string, unknown> = jwtDecode(token);

    return (tokenClaims.oid as string) || (tokenClaims.sub as string);
};

export { getOidFromToken };
