import type { Directive, DirectiveBinding } from 'vue';

interface TooltipProps {
    content: string;
    onlyOnOverflow?: boolean;
}

const BASE_TOP_OFFSET: number = 5;

const tooltipDirective: Directive<HTMLElement, TooltipProps> = ((): Directive<HTMLElement, TooltipProps> => {
    let idCounter: number = 0;
    const mouseOverEventHandlersMap: Map<string, EventListener> = new Map<string, EventListener>();
    const mouseOutEventHandlersMap: Map<string, EventListener> = new Map<string, EventListener>();

    const setTooltipPosition = (element: HTMLElement, tooltip: HTMLDivElement): void => {
        const elementRect: DOMRect = element.getBoundingClientRect();
        const tooltipRect: DOMRect = tooltip.getBoundingClientRect();

        const topOffset: number = elementRect.bottom + BASE_TOP_OFFSET;
        const leftOffset: number = elementRect.left + elementRect.width / 2 - tooltipRect.width / 2;
        const maxLeftOffset: number = document.body.scrollWidth - tooltipRect.width;

        tooltip.style.top = `${topOffset}px`;
        tooltip.style.left = `clamp(0px, ${leftOffset}px, ${maxLeftOffset}px)`;
    };

    return {
        mounted(element: HTMLElement, binding: DirectiveBinding<TooltipProps>): void {
            const elementId: string = element.getAttribute('id') ?? `tooltip-dir-${idCounter++}`;
            element.setAttribute('id', elementId);

            const tooltip: HTMLDivElement = document.createElement('div');
            tooltip.classList.add('tooltip');
            tooltip.innerHTML = binding.value.content;

            let isTooltipVisible: boolean = false;

            mouseOverEventHandlersMap.set(elementId, (): void => {
                if (!binding.value.onlyOnOverflow || element.offsetWidth < element.scrollWidth) {
                    document.body.appendChild(tooltip);
                    setTooltipPosition(element, tooltip);
                    isTooltipVisible = true;
                }
            });

            mouseOutEventHandlersMap.set(elementId, (): void => {
                if (isTooltipVisible) {
                    document.body.removeChild(tooltip);
                    isTooltipVisible = false;
                }
            });

            element.addEventListener('mouseover', mouseOverEventHandlersMap.get(elementId) as EventListener);
            element.addEventListener('mouseout', mouseOutEventHandlersMap.get(elementId) as EventListener);
        },
        beforeUnmount(element: HTMLElement): void {
            const elementId: string = element.getAttribute('id') as string;

            element.removeEventListener('mouseover', mouseOverEventHandlersMap.get(elementId) as EventListener);
            element.removeEventListener('mouseout', mouseOutEventHandlersMap.get(elementId) as EventListener);

            mouseOverEventHandlersMap.delete(elementId);
            mouseOutEventHandlersMap.delete(elementId);
        }
    };
})();

export default tooltipDirective;
