const appVersionLocalStorageKey: string = 'APP_VERSION';

const glsSaveAppVersionInLocalStorage = (appVersion: string): void => {
    window.localStorage.setItem(appVersionLocalStorageKey, appVersion);
};

const glsGetAppVersionFromLocalStorage = (): string => {
    return window.localStorage.getItem(appVersionLocalStorageKey) as string;
};

const glsClearLocalStorageWhenAppVersionChange = (appVersion: string): void => {
    const savedAppVersion = glsGetAppVersionFromLocalStorage();

    if (!savedAppVersion || savedAppVersion !== appVersion) {
        window.localStorage.clear();
        glsSaveAppVersionInLocalStorage(appVersion);
    }
};

const getHostname = (): string => {
    return window.location.hostname.replace('www.', '');
};

export { glsClearLocalStorageWhenAppVersionChange, glsGetAppVersionFromLocalStorage, getHostname };
