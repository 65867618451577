import { type Plugin } from 'vue';
import { createI18n, VueI18nOptions } from 'vue-i18n';

const LOCAL_STORAGE_KEY: string = 'language';

const glsGetLanguageFromLocalStorage = () => {
    let language = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!language) {
        language = 'en';
    }

    return language;
};

const glsSetLanguageInLocalStorage = (language: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, language);
};

const glsCreateI18nInstance = (messages: VueI18nOptions['messages']): Plugin => {
    return createI18n({
        legacy: false, // for composition api
        locale: glsGetLanguageFromLocalStorage() || 'en',
        fallbackLocale: 'en',
        messages
    });
};

export { glsGetLanguageFromLocalStorage, glsSetLanguageInLocalStorage, glsCreateI18nInstance };
