import type { App } from 'vue';
import {
    ElButton,
    ElCheckbox,
    ElCol,
    ElContainer,
    ElDatePicker,
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElOption,
    ElRow,
    ElSelect,
    ElSlider,
    ElSwitch,
    ElTable,
    ElTableColumn,
    ElTooltip
} from 'element-plus';

import 'element-plus/dist/index.css';

const elements = [
    ElContainer,
    ElButton,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElCheckbox,
    ElSelect,
    ElOption,
    ElInputNumber,
    ElTable,
    ElTableColumn,
    ElDialog,
    ElTooltip,
    ElSlider,
    ElSwitch,
    ElDatePicker
];

export default {
    install: (app: App<Element>) => {
        elements.forEach((element) => app.use(element));
    }
};
